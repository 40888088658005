<template>
  <div class="welfare">
      <div class="headBar">
          <div class="headTitle">
              <span>福利中心</span>
          </div>
      </div>
      <div class="content oepnX">
          <WelfareList></WelfareList>
      </div>
  </div>
</template>

<script>
import WelfareList from "@/views/welfare/WelfareList";

export default {
    name:"Welfare",
    components:{
        WelfareList
    }
}
</script>

<style lang="scss" scoped>
 .welfare{
     .headBar{
         //border: solid 1px red;
         display: flex;
         height: 44px;
         align-items: center;
         border-bottom: solid 1px rgb(230,230,230);

         .headTitle{
             color: #000000;
             width: 100%;
             height: 100%;
             display: flex;
             align-items: center;
             justify-content: center;
             //margin-right: 24px;
             font-weight: bold;
             font-size: 18px;
         }
     }
     
     .content{
         height: calc(100vh - 86px - 44px);
         padding: 0 12px;
     }
 }
</style>